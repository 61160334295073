import {
  setAuthCredentials,
  throwErrorMessage,
  clearLocalStorageOnLogout,
} from 'dashboard/store/utils/api';
import wootAPI from './apiClient';
import axios from 'axios';
import createAxios from '../../dashboard/helper/APIHelper'
import { getLoginRedirectURL } from '../helpers/AuthHelper';

const authAxios = createAxios(axios);

export const login = async ({
  ssoAccountId,
  ssoConversationId,
  ...credentials
}) => {
  try {
    const response = await wootAPI.post('auth/sign_in', credentials);
    setAuthCredentials(response);
    clearLocalStorageOnLogout();

    window.location = getLoginRedirectURL({
      ssoAccountId,
      ssoConversationId,
      user: response.data.data,
    });
  } catch (error) {
    throwErrorMessage(error);
  }
};

export const register = async creds => {
  try {
    const response = await wootAPI.post('api/v1/accounts.json', {
      account_name: creds.accountName.trim(),
      user_full_name: creds.fullName.trim(),
      email: creds.email,
      password: creds.password,
      h_captcha_client_response: creds.hCaptchaClientResponse,
    });
    setAuthCredentials(response);
    return response.data;
  } catch (error) {
    throwErrorMessage(error);
  }
  return null;
};

export const verifyPasswordToken = async ({ confirmationToken }) => {
  try {
    const response = await wootAPI.post('auth/confirmation', {
      confirmation_token: confirmationToken,
    });
    setAuthCredentials(response);
  } catch (error) {
    throwErrorMessage(error);
  }
};

export const verify2FA = async ({ token }) => {
  try {
    const response = await authAxios.get(
      `api/v1/auth/two_factors/verify?token=${token}`
    );
    setAuthCredentials(response);
    window.location = '/app';
    return response;
  } catch (error) {
    throwErrorMessage(error);
  }
}

export const resend2FACode = async () => {
  try {
    await authAxios.post('api/v1/auth/two_factors/regenerate');
  } catch (error) {
    throwErrorMessage(error);
  }
}


export const setNewPassword = async ({
  resetPasswordToken,
  password,
  confirmPassword,
}) => {
  try {
    const response = await wootAPI.put('auth/password', {
      reset_password_token: resetPasswordToken,
      password_confirmation: confirmPassword,
      password,
    });
    setAuthCredentials(response);
  } catch (error) {
    throwErrorMessage(error);
  }
};

export const resetPassword = async ({ email }) =>
  wootAPI.post('auth/password', { email });
